<template>
	<section>
		<b-row class="mb-2">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" />		
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active>
			            	<validation-observer
						        ref="form_ref"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm hasshadow" style="border-radius: 5px; margin-top: 10px">
				                	<b-row class="align-center">
							            <b-col lg-12>
							            	<template v-if="userData">
							            		<div class="text-right mb-2">
								                    <template v-if="detail.status == 2 || detail.status == 0">
								            			<b-button type="button" variant="primary" class="btn-df size-18 black btn-dark-blue" style="margin-right: 10px " @click="changeScheduleStatus()" :disabled="disable_editActive">
									                        Schedule
									                    </b-button>
									                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="margin-right: 10px" @click="changeStatustoPublish(detail.id, 1)" :disabled="disable_editActive">
									                        Publish
									                    </b-button>
								            		</template>

								                    <template v-if="detail.status == 1">
								                    	<b-button type="button" variant="basic" class="btn-df size-18 black" @click="changeStatusUnpublish(detail.id, 0)" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px " :disabled="disable_editActive">
									                    	Unpublish
									                    </b-button>
								                    </template>	

								                    <template v-if="detail.status == 3">
								                    	<b-button type="button" variant="danger" class="btn-df size-18 black" style="margin-right: 10px; background-color: #DD3E47 !important " @click="unscheduleAction" :disabled="disable_editActive">
									                        Unschedule
									                    </b-button>
								                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-dark-blue" style="margin-right: 10px " @click="rescheduleAction" :disabled="disable_editActive">
									                        Reschedule
									                    </b-button>
								                    </template>

								                    <!-- <b-button type="button" variant="primary" class="btn-df size-18 black" v-if="!isEdit" @click="editAction">
								                        Edit
								                    </b-button> -->
								                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelEdit" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveEdit(detail.id)" >
								                        Save
								                    </b-button>
								                </div>
							            	</template>
							            	<template v-else>
							            		<div class="text-right mb-2">		
								                    <template v-if="detail.status == 2 || detail.status == 0">
								            			<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px">
									                        Schedule
									                    </b-button>
									                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px">
									                        Publish
									                    </b-button>
								            		</template>

								                    <template v-if="detail.status == 1">
								                    	<b-button type="button" variant="basic" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px ">
									                    	Unpublish
									                    </b-button>
								                    </template>	

								                    <template v-if="detail.status == 3">
								                    	<b-button type="button" variant="danger" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px;>
									                        Unschedule
									                    </b-button>
								                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px ">
									                        Reschedule
									                    </b-button>
								                    </template>
								                    <template v-if="userData">
								                    	<b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEdit(detail.id)">
									                        Edit
									                    </b-button>
								                    </template>
								                    <template v-else>
								                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>Edit</b-button>
								                    </template>
								                </div>
							            	</template>

							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 d-block">              
				                      	<b-row style="width: 100%; margin: 0;"> 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="AAID" label-cols-lg="4" class="star-required input-group-label">
				                                    <b-form-input
				                                    	v-model="detail.id"
				                                      	placeholder="Useful Info (P) ID"
				                                      	disabled
				                                    ></b-form-input>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Published Date" label-cols-lg="4" class="star-required">
				                                     <flat-pickr
					                                      	class="form-control"   
					                                      	v-model="firstPublishDate_Schedule"
					                                      	:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
					                                      	placeholder="dd/mm/yy"
					                                      	disabled
					                                    />
					                            </b-form-group>
				                          	</b-col> 			                          		
				                          	<b-col lg="6" class="px-0">
				                          	
				                          		<b-form-group label="Image Banner (1600 x 900)" label-cols-lg="4" class="uploadImage pl-1600-lg-10">
				                          			
				                          			<div class="upload-show-name container-box" v-if="detail.image" style="flex-direction: column">
				                          				<div class="close-upload cursor-pointer" @click="closeUpload()">
				                          					<feather-icon
																icon="XCircleIcon"
																size="24"
																color="#DD3E47"
															/>
				                          				</div>
				                          				<p>
				                          					<feather-icon
																icon="ImageIcon"
																size="30"
															/>
				                          				</p>
				                          				<template v-if="detail.image.name">{{ detail.image.name }}</template>
				                          				<template v-else>
				                          					<p style="max-width: 90%; word-break: break-all; text-align: center ">{{ detail.image ? detail.image : '' | splitStr }}</p>
				                          				</template>
					                          		</div>                   		
				                          			<div v-else class="bg container-box" :style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/Upload-image.png')})` }">
							                            <b-form-file 
							                            	v-model="detail.image" 
							                            	plain 
							                            	accept=".jpg, .png" 
							                            	ref="fileImage"
															class="cursor-pointer"
							                            	:disabled="isDisabled"
							                            >
					                                    </b-form-file>		                 
					                                </div>
					                               
				                                </b-form-group>
				                          	</b-col> 	 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                                <v-select
					                                    label="title"
					                                    class="disabled-field"
					                                    :options="selectStatus"
					                                    v-model="statusDetailText" 
					                                    placeholder="Select status"
					                                    disabled
				                                    />
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Title" label-cols-lg="2" class="star-required input-group-label">
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Title"
					                                  vid="title"
					                                  rules="required"                
					                                >
					                                    <b-form-input
					                                    	v-model="detail.title" 
					                                      	placeholder="Name"
					                                      	:formatter="length100"
					                                    ></b-form-input>    
					                                 	<small class="text-danger">{{ errors[0] }}</small>
                                					</validation-provider>              
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Description" label-cols-lg="2" class="star-required"> 
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Description"
					                                  vid="description"
					                                  rules="required"               
					                                >                           
					                                    <b-form-textarea
					                                    	v-model="detail.description"
					                                      	placeholder="Description"
					                                      	rows="5"
					                                      	:formatter="length1000"
					                                    ></b-form-textarea>
					                                    <small class="text-danger">{{ errors[0] }}</small>
				                                    </validation-provider>       
				                              	</b-form-group>
				                          	</b-col>
				                      	</b-row>
					                </div>
					            </b-form> 
					           	<b-modal
								    id="modal-Schedule"
								    ref="modaSchedulel"
								    title="Schedule Content Info"	
								    :no-close-on-backdrop="true"					   
									>
								    <b-row style="padding-right: 30px">
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="Date From" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required">
											  	<div class="calendarIcon">
													<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
												</div>
												<flat-pickr
													v-model="date_schedule"
													placeholder="Select a date"
													class="form-control fieldCalendar"           
													:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: minScheduleDate }"
													required
												/>    
				                            </b-form-group>
				                        </b-col>
				                        <b-col lg="12" class="px-0">
				                            <b-form-group label="Time" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required">
				                              	<validation-provider
					                                #default="{ errors }"
					                                name="Time"
					                                vid="time_schedule"
					                                rules="required"               
					                            >   
											        <v-select
											        	v-model="time_schedule"
					                                    label="time"
					                                    :options="getListTime_add"
					                                    placeholder="00:00"
					                                    class="timebox black-child-im form-control px-0 border-none"
					                                />
					                                <small class="text-danger">{{ errors[0] }}</small>
					                            </validation-provider>
				                            </b-form-group>
				                        </b-col>
				                    </b-row>   
				                    <template #modal-footer="{ cancel }">
				                        <b-button size="sm" variant="static" class="btn-default" @click="cancelSchedule">
				                          	Cancel
				                        </b-button>
				                        <b-button size="sm" variant="primary" class="btn-primary" @click="saveSchedule" id="btn-dark-blue">
				                          	Schedule
				                        </b-button>
				                    </template> 
								</b-modal>
								<!-- status: Schedule -> Reschedule button -> modal -->
								<b-modal
								    ref="modaReSchedulel"
								    title="Schedule Content Info"	
								    :no-close-on-backdrop="true"
								    @close="cancelReschedule"					   
									>
								    <b-row style="padding-right: 30px">
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="Date From" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required">
											  	<div class="calendarIcon">
													<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
												</div>
												<flat-pickr
													v-model="detail.published_date"
													placeholder="Select a date"
													class="form-control fieldCalendar"           
													:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: minScheduleDate_rechedule }"
													required
												/>
				                            </b-form-group>
				                        </b-col>
				                        <b-col lg="12" class="px-0">
				                            <b-form-group label="Time" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required">
				                              	<validation-provider
					                                #default="{ errors }"
					                                name="Time"
					                                vid="time_schedule"
					                                rules="required"               
					                            >   
											        <v-select
											        	v-model='detail.publised_time'
					                                    label="time"
					                                    :options="getListTime"
					                                    placeholder="00:00"
					                                    class="timebox black-child-im form-control px-0 border-none"
					                                />
					                                <small class="text-danger">{{ errors[0] }}</small>
					                            </validation-provider>
				                            </b-form-group>
				                        </b-col>				                        
				                    </b-row>   
				                    <template #modal-footer="{ cancel }">
				                        <b-button size="sm" variant="static" class="btn-default" @click="cancelReschedule()">
				                          	Cancel
				                        </b-button>
				                        <b-button size="sm" variant="primary" class="btn-dark-blue" @click="saveReschedule" id="btn-dark-blue">
				                          	Reschedule
				                        </b-button>
				                    </template> 
								</b-modal>
								<!-- status: Schedule -> Unschedule button -> modal -->	
								<b-modal
								    ref="modaUnSchedulel"
								    title="Unschedule Announcement"	
								    :no-close-on-backdrop="true"					   
									>
									<b-col lg="12" class="px-0 mb-2 size-16 text-center" style="color: #DD3E47">
							    		<strong>Confirm Unschedule Announcement?</strong>
							    	</b-col>
								    <b-row style="padding-right: 30px">
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="Date From" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold">   
													<div class="calendarIcon">
														<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
													</div>
					                                <flat-pickr
					                                    v-model="detail.published_date"
					                                    placeholder="Select a date "
					                                    class="form-control disabled-daft fieldCalendar"           
					                                    :config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d'}"
					                                    disabled
														required
					                                />
				                            </b-form-group>
				                        </b-col>
				                        <b-col lg="12" class="px-0">
				                            <b-form-group label="Time" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold">
				                              	<validation-provider
					                                #default="{ errors }"
					                                name="Time"
					                                vid="time_schedule"
					                                rules="required"  
					                            >   
											        <v-select
											        	v-model='detail.publised_time'
					                                    label="time"
					                                    :options="getListTime"
					                                    placeholder="00:00"
					                                    class="timebox black-child-im disabled-daft form-control px-0 border-none"
					                                    disabled
					                                />
					                                <small class="text-danger">{{ errors[0] }}</small>
					                            </validation-provider>
				                            </b-form-group>
				                        </b-col>
				                    </b-row>   
				                    <template #modal-footer="{ cancel }">
				                        <b-button size="sm" variant="static" class="btn-default" @click="cancel()">
				                          	Cancel
				                        </b-button>
				                        <b-button size="sm" variant="danger" class="btn-dark-blue" @click="saveUnschedule" id="btn-dark-blue" style="background-color: #DD3E47 !important ">
				                          	Unschedule
				                        </b-button>
				                    </template> 
								</b-modal>
					        </validation-observer>
			            </b-tab>                           
			        </b-tabs>    
			        <div class="table-custom">
			        	<b-card class="px-table-0 document-table w-50-percent-cl-3 w-17-percent-cl-2 w-17-percent-cl-2">
		            		<div class="text-right mr-2 mb-2">
		            			<template v-if="userData">
				                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" v-b-modal.addDocumentPopup>
				                        Add Document
			                    	</b-button>		
			                    </template>
			                    <template v-else>
			                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
				                        Add Document
				                    </b-button>		
			                    </template>			                    
			                </div>
			                <b-table small :fields="fields" :items="files" tbody-tr-class="size-14 table-row" show-empty @head-clicked="sortClicked">
			                	<template #head()="data">
						    		<span class="d-block text-center">{{ data.label }}</span>
						    	</template>	
						    	<template #head(id)="data">
									<span>{{ data.label }}</span>
									<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc' }]" style="position: relative; left: 20px;">
							        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
							        </span>
								</template>	
								<template #head(attach_file_title)="data">
									<span>{{ data.label }}</span>
								</template>		
								<template #cell(id)="data">					
									<span v-if="data.field.key == sort.field && sort.type == 'desc'">
										{{ totalDocument_2 - data.index }}
									</span>
									<span v-else>
										{{ data.index + 1 }}
									</span>
								</template>	
								<template #cell(updated_at)="data">
									<span class="d-block text-center">{{ data.item.updated_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
								</template>				
								<template #cell(attachable_type)="data">
									<span class="d-block text-center">{{ data.item.attachable_type }}</span>
								</template>	
								<template #cell(attach_file_title)="data">
									<span class="maxTwoRow">{{ data.item.attach_file_title }}</span>
								</template>			
								<template #cell(attach_content_type)="data">
									<span class="text-center maxTwoRow mx-auto">{{ data.item.attach_content_type ? data.item.attach_content_type : '-' }}</span>
								</template>				
								<template #cell(action)="data">
									<div class="d-block text-center">
										<span class="px-05 cursor-pointer" @click.stop="downloadDocument(data.item.attach_file_name, data.item.attach_file_title)">
											<feather-icon
									            icon="DownloadIcon"
									            size="22"
									          />
										</span>
										<template v-if="userData">
											<span class="px-05 cursor-pointer" @click.stop="editDocument(data.item.id, data.item.attach_file_title, data.item.attach_file_name, data.item.attach_content_type)">
												<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
												</svg>
											</span>
											<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id, data.item.attach_file_title)">
												<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
												</svg>
											</span>
										</template>
										<template v-else>
											<span class="px-05">
												<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
												</svg>
											</span>
											<span class="px-05">
												<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
												</svg>
											</span>
										</template>
									</div>
								</template>	
			                	<template #empty="scope">
							      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
							    </template>
			                </b-table>
			                <b-modal id="addDocumentPopup" ref="modal_addDocument" title="Add New Document" hide-header-close ok-title="Save" centered>
			                	<validation-observer
								    ref="addDocument_ref"
								    #default="{invalid}" 
								    @close="cancelAddDocument">
				                    <b-row style="padding-right: 30px">	
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="Name" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required input-group-label">
				                              	<validation-provider
				                                  #default="{ errors }"
				                                  name="Name"
				                                  vid="name"
				                                  rules="required"                
				                                >
					                                <b-form-input
					                                	v-model="fieldsDocument.name" 
					                                  	placeholder="Name"
					                                  	type="text"
					                                  	col-md="7"
					                                ></b-form-input>
					                                <small class="text-danger">{{ errors[0] }}</small>
					                            </validation-provider>
				                            </b-form-group>
				                        </b-col>
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="File Upload" label-cols-lg="3" class="uploadImage text-lg-right label-bold star-required">
				                                	
			                                	<div class="upload-show-name container-box px-1" v-if="fieldsDocument.file" style="flex-direction: column; word-break: break-all">
			                          				<div class="close-upload cursor-pointer" @click="closeDocumentUpload">
			                          					<feather-icon
															icon="XCircleIcon"
															size="24"
															color="#DD3E47"
														/>
			                          				</div>
			                          				<p>
			                          					<feather-icon
															icon="ImageIcon"
															size="30"
														/>
			                          				</p>				
				                          			{{ fieldsDocument.file ? fieldsDocument.file.name : '' }}
				                          		</div>                   		
			                          			<div v-else>
			                          				<validation-provider
						                                #default="{ errors }"
						                                name="File Upload"
						                                vid="file"
						                                rules="required"                
						                            >
				                          				<div class="bg container-box cursor-pointer" :style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/UploadFile.png')})`}">
									                            <b-form-file 
									                            	v-model="fieldsDocument.file" 
									                            	plain 
									                            	accept=".jpg, jpeg, .png, .zip, .mp4, .pdf, .doc, .docx, .bin, xlsx, pptx, gif" 
									                            	ref="fileImage"
																	class="cursor-pointer"
																	>
							                                    </b-form-file>
				                          				</div>
			                          					<small class="text-danger text-left d-block">{{ errors[0] }}</small>
			                          				</validation-provider>	 
				                                </div>
				                            </b-form-group>
				                        </b-col>			                        
				                        <b-col lg="12" class="px-0">
				                            <b-form-group label="Remarks" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold">
				                                <b-form-textarea
				                                	v-model="fieldsDocument.remarks" 
				                                 	placeholder="Remarks"
				                                  	rows='4'
				                                  	:formatter="length1000"
				                                ></b-form-textarea>
				                            </b-form-group>
				                        </b-col>
				                    </b-row>  
				                </validation-observer>
			                    <template #modal-footer="{ cancel }">
			                        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelAddDocument()">
			                          	Cancel
			                        </b-button>
			                        <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="addDocument('useful_info')">
			                          	Save
			                        </b-button>          
			                    </template>					                
			                </b-modal>
			                <b-modal id="editDocumentPopup" ref="modal_editDocument" title="Edit Document" hide-header-close ok-title="Save" centered>
			                	<validation-observer
								    ref="editDocument_ref"
								    #default="{invalid}" >
				                    <b-row style="padding-right: 30px">	
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="Name" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required input-group-label">
				                              	<validation-provider
				                                  #default="{ errors }"
				                                  name="Name"
				                                  vid="name"
				                                  rules="required"                
				                                >
					                                <b-form-input
					                                	v-model="fieldsDocumentShow.name" 
					                                  	placeholder="Name"
					                                  	type="text"
					                                  	col-md="7"
					                                ></b-form-input>
					                                <small class="text-danger">{{ errors[0] }}</small>
					                            </validation-provider>
				                            </b-form-group>
				                        </b-col>
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group id="" label="File Upload" label-cols-lg="3" class="uploadImage text-lg-right label-bold star-required">
			                                	<div class="upload-show-name container-box" v-if="fieldsDocumentShow.file" style="flex-direction: column">
			                          				<div class="close-upload cursor-pointer" @click="closeEditDocumentUpload">
			                          					<feather-icon
															icon="XCircleIcon"
															size="24"
															color="#DD3E47"
														/>
			                          				</div>
			                          				<p>
			                          					<feather-icon
															icon="ImageIcon"
															size="30"
														/>
			                          				</p>				
				                          			{{ fieldsDocumentShow.file.name ? fieldsDocumentShow.file.name : fieldsDocumentShow.file }}	
				                          		</div>                   		
			                          			<div v-else>
			                          				<validation-provider
						                                #default="{ errors }"
						                                name="File Upload"
						                                vid="file"
						                                rules="required"                
						                            >
				                          				<div class="bg container-box" :style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/UploadFile.png')})`}">
									                            <b-form-file 
									                            	v-model="fieldsDocumentShow.file" 
									                            	plain 
									                            	accept=".jpg, jpeg, .png, .zip, .mp4, .pdf, .doc, .docx, .bin, xlsx, pptx, gif" 
									                            	ref="fileImage"
																	class="cursor-pointer"
																	>
							                                    </b-form-file>
				                          				</div>
			                          					<small class="text-danger text-left d-block">{{ errors[0] }}</small>
			                          				</validation-provider>	 
				                                </div>
				                            </b-form-group>
				                        </b-col>			                        
				                        <b-col lg="12" class="px-0">
				                            <b-form-group label="Remarks" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold">
												<b-form-textarea
													v-model="fieldsDocumentShow.remarks" 
													placeholder="Remarks"
													rows='4'
													:formatter="length1000"
												></b-form-textarea>
				                            </b-form-group>
				                        </b-col>
				                    </b-row>  
				                </validation-observer>
			                    <template #modal-footer="{ cancel }">
			                        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancel()">
			                          	Cancel
			                        </b-button>
			                        <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveEditDocument">
			                          	Save
			                        </b-button>          
			                    </template>					                
			                </b-modal>
		            	</b-card>	
			        </div> 
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'

	import Datepicker from 'vuejs-datepicker';

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../model/globalAdmin'
    const moment = require('moment');

	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "administrations_userfulInfo_edit": "" },
				form: {date: null},			  
			    selectStatus: [
			    	{ title: 'Unpublished', value: '0' },
			    	{ title: 'Published', value: '1' },
			    	{ title: 'Draft', value: '2' },
			    	{ title: 'Schedule', value: '3' },
			    	{ title: 'Unschedule', value: '4' },
			    ],			   
			    date_schedule: null,
			    time_schedule: null,

			    detail: [],
			    statusDetailText: null,
			    isEdit: false,
			    fields: [
			    	{key: 'id', label: 'No.'},
			    	{key: 'attach_file_title', label: 'Document Name'},
			    	{key: 'attach_content_type', label: 'Remarks'},
			    	{key: 'updated_at', label: 'Uploaded Date'},
			    	{key: 'action', label: 'Actions'},
			    ],
			    files: [],
			    totalDocument_2: 0,
			    sort: {
		      		"field": "id",
		      		"type": "asc"
		      	},
		      	fieldsDocument: {
		      		name: null,
		      		file: null,
		      		remarks: null
		      	},
		      	attachable_type: null,
		      	fieldsDocumentShow: {
		      		name: null,
		      		file: null,
		      		remarks: null
		      	},
		      	formatTimeReschedule: null,
		      	firstPublishDate_Schedule: null,
		      	firstPublishTime_Schedule: null,
            }
		},		
		computed: {
          	minScheduleDate(){            
              	var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM-DD')
              	return now
          	},
          	minScheduleDate_rechedule(){
          		var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM-DD')
              	return now
          	},
          	getListTime() {
	            let times = [];
	            for(let i = 0; i < 24; i++){
	            	let moment1 = this.detail.published_date ? moment(this.detail.published_date) : moment()
	                let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time1)) {
	                    times.push({
	                        time: time1.format('HH:mm'),
	                        value: time1.format('HH:mm')
	                    })
	                }
	                let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time2)) {
	                    times.push({
	                        time: time2.format('HH:mm'),
	                        value: time2.format('HH:mm')
	                    })
	                }
	            }
	            return times;
	        },
	        getListTime_add(){
	        	let times = [];
	            for(let i = 0; i < 24; i++){
	            	let moment1 = this.date_schedule ? moment(this.date_schedule) : moment()
	                let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time1)) {
	                    times.push({
	                        time: time1.format('HH:mm'),
	                        value: time1.format('HH:mm')
	                    })
	                }
	                let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time2)) {
	                    times.push({
	                        time: time2.format('HH:mm'),
	                        value: time2.format('HH:mm')
	                    })
	                }
	            }
	            return times;
	        }
        },
        filters: {
        	splitStr(val) {
        		if(!val) return null
        		if(typeof val == 'string') return val.split("/")[val.split("/").length -1 ]
        	}
        },
		created(){
			this.permission = this.checkPermission(this.userData,"Administration");
			this.fetchItem()
			this.fetchDocumentInfo()			
		},
		methods: {
			closeUpload(){
				if(!this.isDisabled){
					this.detail.image = null
				}				
			},
			fetchItem(){
				this.$store
				.dispatch('auth/fetchUserfulDetail', {
					id: this.$route.params.id	
				})
				.then(response => {
					this.detail = response.data.data;
					// console.log('detail list: ', this.detail)
					this.labels['administrations_userfulInfo_edit'] = response.data.data.title
					document.title = response.data.data.title + ' - ITEES | Your Global Partner of Choice'

					if(this.detail.status == 0) this.statusDetailText = 'Unpublished'
					if(this.detail.status == 1) this.statusDetailText = 'Published'
					if(this.detail.status == 2) this.statusDetailText = 'Draft'
					if(this.detail.status == 3) this.statusDetailText = 'Schedule'
					if(this.detail.status == 4) this.statusDetailText = 'Unschedule'

					this.detail.publised_time = moment(this.detail.publised_time, 'HH:mm:ss').format('HH:mm');
					this.firstPublishDate_Schedule = this.detail.published_date
					this.firstPublishTime_Schedule = this.detail.publised_time
				})
				.catch((error) => {
					console.log(error)
				})
			},			
			changeScheduleStatus(){				
				this.$refs.form_ref.validate().then(success => {
					if (success){
						this.$refs['modaSchedulel'].show()		
						this.date_schedule = null
						this.time_schedule = null				
					}					
				})				
			},
			cancelSchedule(){
				this.date_schedule = null
				this.time_schedule = null
			},
			saveSchedule(){
				this.$refs.form_ref.validate().then(success => {
					if (success){
						if(this.date_schedule){
							this.$store
							.dispatch('auth/changeStatusSchedule', { 
								id: this.$route.params.id,
								status: 3,
								published_date: this.date_schedule,
								published_time: this.time_schedule.value
							})
							.then(response => {
								this.$refs['modaSchedulel'].hide()	
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: this.detail.title + ' Scheduled!',
				                          variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )	
				                this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
							})
							.catch((error) => {
								console.log(error)
							})
						}	
						else{
							this.$toast({
			                    component: ToastificationContent,
			                    props: {
			                        title: 'Please select Date from!',
			                        variant: 'danger',
			                      },
			                    },
			                    {
			                      position: 'top-center'
			                    }
			                )
						}		
					}
				})	
			},
			changeStatustoPublish(id, status){
				this.$bvModal.msgBoxConfirm('Are you sure you want to publish this item?', {
		          	title: 'Publish',
		          	size: 'sm',
		          	buttonSize: 'sm',
		          	okVariant: 'primary',
		          	okTitle: 'Publish',
		          	cancelTitle: 'Cancel',
		          	footerClass: 'p-2 modal-delete',
		          	hideHeaderClose: false,
		          	centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.changeStatus(id, status) 
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			changeStatus(id, status){
				this.$store
				.dispatch('auth/changeStatus', { 
					id: id,
					status: status
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: this.detail.title + ' Published!',
	                          variant: 'success',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )
	                this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })		
				})
				.catch((error) => {
					console.log(error)
				})
			},
			editAction(){
				this.isEdit = true
			},
			cancelEdit(){
				this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
			},
			saveEdit(id){	
				this.$refs.form_ref.validate().then(success => {
					if (success){
						
						if(this.detail.status == 3){

							let userful_field = {
								image: this.detail.image,
								status: this.detail.status,
			                  	title: this.detail.title,
			                  	description: this.detail.description,         
			                  	type: 0,  
			                  	published_date: this.detail.published_date,  
		                  		published_time: this.detail.publised_time 
			                }
			                this.$store
							.dispatch('auth/editUseful_info_Schedule', {
								id: id,
								data: userful_field
							})
							.then(response => {
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: this.detail.title + ' Updated!',
				                          variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
								this.isEdit = false
								this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
							})
							.catch((error) => {
								console.log(error)
								this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
							})
						}
						else{
							let userful_field = {
								image: this.detail.image,
								status: this.detail.status,
			                  	title: this.detail.title,
			                  	description: this.detail.description,         
			                  	type: 0,  
			                }
							this.$store
							.dispatch('auth/editUseful_info', {
								id: id,
								data: userful_field
							})
							.then(response => {
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: this.detail.title + ' Updated!',
				                          variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
								this.isEdit = false
								 this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
							})
							.catch((error) => {
								console.log(error)
								this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
							})
						}

					}
				})
			},
			changeStatusUnpublish(id, status){
				this.$bvModal.msgBoxConfirm('Are you sure you want to unpublish this item?', {
		          	title: 'Unpublish',
		          	size: 'sm',
		          	buttonSize: 'sm',
		          	okVariant: 'danger',
		          	okTitle: 'Unpublish',
		          	cancelTitle: 'Cancel',
		          	footerClass: 'p-2 modal-delete',
		          	hideHeaderClose: false,
		          	centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.changetoUnpublish(id, status) 
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			changetoUnpublish(id, status){
				this.$store
				.dispatch('auth/changeStatus', { 
					id: id,
					status: status
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: this.detail.title + ' Unpublish!',
	                          variant: 'success',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )		
	                this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},
			rescheduleAction(){
				this.$refs['modaReSchedulel'].show()	
			},
			saveReschedule(){
				if(this.detail.published_date){
					let userful_field = {
						image: this.detail.image,
						status: this.detail.status,
	                  	title: this.detail.title,
	                  	description: this.detail.description,         
	                  	type: 0,  
	                  	published_date: this.detail.published_date,  
	              		published_time: this.detail.publised_time.value ? this.detail.publised_time.value : this.detail.publised_time
	                }
	                this.$store
					.dispatch('auth/editUseful_info_Schedule', {
						id: this.$route.params.id,
						data: userful_field
					})
					.then(response => {
						this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                          title: this.detail.title + ' Rescheduled!',
		                          variant: 'primary',
		                      	},
		                	},
		                	{
		                      	position: 'top-center'
		                	}
		                )
						this.isEdit = false
						this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
					})
					.catch((error) => {
						console.log(error)
						this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                        	title: error.response.data.message,
	                        	variant: 'danger',
	                      	},
	                    },
	                    {
	                      position: 'top-center'
	                    })
					})
				}
				else{
					this.$toast({
	                    component: ToastificationContent,
	                    props: {
	                        title: 'Please select Date from!',
	                        variant: 'danger',
	                      },
	                    },
	                    {
	                      position: 'top-center'
	                    }
	                )
				}		
			},
			cancelSchedule(){ 
				this.$refs['modaSchedulel'].hide()
				this.date_schedule = null
				this.time_schedule = null	
			},
			cancelReschedule(){ 
				this.$refs['modaReSchedulel'].hide()
				this.detail.published_date = this.firstPublishDate_Schedule
				this.detail.publised_time = this.firstPublishTime_Schedule
			},
			unscheduleAction(){
				this.$refs['modaUnSchedulel'].show()
			},
			saveUnschedule(){
				this.$store
				.dispatch('auth/changeStatus', { 
					id: this.$route.params.id,
					status: 4
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: this.detail.title + ' Unscheduled!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )		
	                this.$router.push({ name: 'staff_administrations', query: { tab: 1 } })	
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},

			// document
			fetchDocumentInfo(){
				this.$store
				.dispatch('auth/fetchDocumentInfo', {
					filter: {
					  	"list_search": [
						    {
						      "attachable_id": this.$route.params.id,
						      "attachable_type": "useful_info"
						    }
						]
					},
					sort: this.sort
				})
				.then(response => {
					this.files = response.data.data
					this.totalDocument_2 = response.data.data.length
					//console.log('files attachable_type: ', response.data.data.attachable_type)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			sortClicked(key) {
				if(this.sort.field == key){
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				}
				else{					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchDocumentInfo(); 
			},
			showDeleteAction(id, title) {
		        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          	title: 'Delete',
		          	size: 'sm',
		          	buttonSize: 'sm',
		          	okVariant: 'danger',
		          	okTitle: 'Delete',
		          	cancelTitle: 'Cancel',
		          	footerClass: 'p-2 modal-delete',
		          	hideHeaderClose: false,
		          	centered: true
		        })
		          .then(value => {
		            	if(value){
		            		// if confirm yes => call api delete => toast => call list API
		            		this.deleteDocument(id, title)	  
		            	}
		          })
		          .catch(err => {
		            // An error occurred
		          })
		    },
		    deleteDocument(id, title){
		    	this.$store
				.dispatch('auth/deleteDocument', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )
	                this.fetchDocumentInfo()
	                			
				})
				.catch((error) => {
					console.log(error)
				})
		    },
		    downloadDocument(path, title){
		    	const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
		  		axios({
		        	method: 'get',
		        	url: url,
		        	responseType: 'blob',
		      	})
		      	.then(response => {		        
		        	this.forceFileDownload(response, title)	           
		      	})
		      	.catch(() => console.log('error occured'))
		    },
		    forceFileDownload(response, namFile){
		      	const url = window.URL.createObjectURL(new Blob([response.data]))
		      	const link = document.createElement('a')
		      	link.href = url
		      	link.setAttribute('download', namFile)
		      	document.body.appendChild(link)
		      	link.click()
		    },
		    addDocument(type){
		    	this.$refs.addDocument_ref.validate().then(success => {
					if (success){

						var fileSize_true = null 

						if(this.fieldsDocument.file.size <= 52428800){

							fileSize_true = this.fieldsDocument.file

							let addDocument = {
								attachable_id: this.$route.params.id,
								name: this.fieldsDocument.name,
								file: fileSize_true,		                  	
			                  	remarks: this.fieldsDocument.remarks,  
			                  	attachable_type: type, 
			                }
							this.$store
							.dispatch('auth/addDocument', addDocument)
							.then(response => {
								this.$refs['modal_addDocument'].hide()	
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: this.fieldsDocument.name + ' Created!',
				                          variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
				                this.fieldsDocument.file = null
				                this.fieldsDocument.name = null
				                this.fieldsDocument.remarks = null
				                this.fetchDocumentInfo()
							})
							.catch((error) => {
								console.log(error)
								this.$toast({
										component: ToastificationContent,
										props: {
											title: error.response.data.message,
											variant: 'danger',
										},
									},
									{
										position: 'top-center'
									}
								)
							})
						}
						else{
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: 'Uploaded file is too large, maximum 50MB',
			                          variant: 'danger',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
						}
					}
				})
		    },
		    cancelAddDocument(){
		    	this.$refs['modal_addDocument'].hide()	
		    	this.fieldsDocument.name = null
				this.fieldsDocument.file = null            	
		        this.fieldsDocument.remarks = null
		    },
		    closeDocumentUpload(){
				this.fieldsDocument.file = null
			},
			editDocument(id, name, path, remarks){	

				var path_name = path.split("/")[path.split("/").length -1 ]	
				var format_name = name.split('.', length - 1)

				this.$refs['modal_editDocument'].show()	
				this.fieldsDocumentShow.name = format_name[0]
				this.fieldsDocumentShow.id = id
				this.fieldsDocumentShow.file = path_name
				this.fieldsDocumentShow.remarks = (remarks = null || remarks == '-') ? null : remarks
			},
			saveEditDocument(){
		    	this.$refs.editDocument_ref.validate().then(success => {

					if (success){

						var fileSize_true = null 

						if(this.fieldsDocumentShow.file.size && this.fieldsDocumentShow.file.size > 52428800){
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: 'Uploaded file is too large, maximum 50MB',
			                          variant: 'danger',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
						}
						else{
							fileSize_true = this.fieldsDocumentShow.file
							let documentField = {
								name: this.fieldsDocumentShow.name,
								file: fileSize_true,		                  	
			                  	remarks: this.fieldsDocumentShow.remarks,
			                }
			                // console.log('list: ', documentField)
							this.$store
							.dispatch('auth/editDocument', 
								{
									id: this.fieldsDocumentShow.id,
									data: documentField
								}
							)
							.then(response => {
								this.$refs['modal_editDocument'].hide()	
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          	title: this.fieldsDocumentShow.name + ' Updated!',
				                          	variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )			                
				                this.fetchDocumentInfo()
							})
							.catch((error) => {
								// console.log(error.response)
								if(error && error.response.data.code == 422){
									this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          	title: 'Document name existed!',
				                          	variant: 'danger',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	})
								}
							})
						}	
					}
				})
		    },
			closeEditDocumentUpload(){
				this.fieldsDocumentShow.file = null
			},
			length100(e){
				return String(e).substring(0,100)
			},
			length1000(e){
				return String(e).substring(0,1000)
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	.document-table thead tr th:first-child{
		width: 150px
	}
</style>